/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */


//Production
export const environment = {
  production: true,
  apiUrl:'https://oneringproduction-api.azurewebsites.net/api/',
  apiCode:'v3EH/EEXb5fsfuYliEjodaSMIkh/bsnmq8jED/ISnSUMzvaYG8ZHOg==',

  sentryShowdialog: false,
  sentryEnvironment: 'Prod',
  sentryRelease: 'EnrollmentPortal_Production_EnrollmentPortal_QA_main_9_13_2024.1_9_18_2024.1',

  captchaKey: '6LeJmuwoAAAAAOnNw6AWc0WWoOC43hqWLr2ki3ct',
  scheduleApptMonth:'6',

};
