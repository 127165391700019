/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";

import {ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { ViewEncapsulation} from '@angular/core';
import { InvokeFunctionExpr } from '@angular/compiler';
import { UtilityService } from 'src/app/services/utility.service';
import { MatDialog } from '@angular/material/dialog';
import { EnrollmentSuccessComponent } from '../../enrollment-success/enrollment-success.component';
import {Input} from '@angular/core';
import { Router } from '@angular/router';
import { RebateService } from 'src/app/services/rebate.service';

@Component({
  selector: 'app-online-app',
  templateUrl: './online-app.component.html',
  styleUrls: ['./online-app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OnlineAppComponent implements OnInit {
  TabModules:any=[];
  myData:any;
  officeid= localStorage.getItem('currentUtility');
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  selectedTabIndex:number = 0;
  @Input() data: any;
  tabCount = 0;
  constructor(private router: Router,private spinner: NgxSpinnerService,public utilityService: UtilityService,private dialog: MatDialog,
    private rebate:RebateService) { }

  ngOnInit(): void {


    this.myData=history.state.data;
    console.log("MyData",this.myData);

    this.spinner.show();
    this.gettabinfo();
    this.TabModules.forEach(element => {
      this.rebate.setItem(element.Modulepath, false);
    });
  }
  private gettabinfo(){
    this.rebate.gettabcomponentinfo(this.myData.ProgramdetailId).subscribe(res => {
      //console.log(res);
      this.TabModules=res.Modules;
      this.tabCount=this.TabModules.length;
      this.spinner.hide();


   },
   err => {
    this.spinner.hide();

   })
  }

  onTabClick(event) {
    this.selectedTabIndex = event.index;
    const selectedIndex = event.index;
    console.log("Selcted Index" + selectedIndex); //---To trouble shoot selected tab index
    if (selectedIndex == this.tabCount - 1 && this.tabCount != 1){
      let workorderId=this.myData.WorkorderId
      this.invokeSubmit(workorderId);


    }
    const divElements = document.querySelectorAll('div.box');
    for (let i = 0; i < divElements.length; i++) {
      divElements[i].classList.remove('active');
}
divElements[selectedIndex].classList.add('active');
  }

//to invoke Submit and success components
  private invokeSubmit(workorderId){
    this.spinner.show();
    console.log('officeidsubmit:',this.officeid);
    let userId='sfen_user';
    let isEP=1;
    this.utilityService.submitWorkorder(this.officeid,workorderId,userId,isEP).subscribe((res:any) => {

      if(res.Action== false)
      {
        this.spinner.hide();
        let dialogBoxSettings = {
          height: '80%',
          width: '80%',
          disableClose: true,
          hasBackdrop: true,

        };
      const dialogRef = this.dialog.open(EnrollmentSuccessComponent,dialogBoxSettings);
      }

  });

  }

  shouldDisableTab(index: number): boolean {
    let ls_disable = true;
    let allITabsFilled = 0;
    if (index === this.tabCount - 1) {
      this.TabModules.forEach(element => {
        if (this.rebate.getItem(element.Modulepath) === true) {
          allITabsFilled++;
        }
      });
    }
    if (allITabsFilled == this.tabCount - 1) {
      ls_disable = false;
    }
    if (index !== this.tabCount - 1) {
      ls_disable = false;
    }
    if (ls_disable === true) {
      const divElements = document.querySelectorAll('div.box');
      for (let i = 0; i < divElements.length; i++) {
        divElements[i].classList.remove('inactive');
      }
      divElements[this.tabCount - 1]?.classList?.add('inactive');
    } else {
      const divElements = document.querySelectorAll('div.box');
      for (let i = 0; i < divElements.length; i++) {
        divElements[i].classList.remove('inactive');
      }
    }
    if(index==4)
      {
        ls_disable = true;
      }
    return ls_disable;
  }
  getIconVisibility(index: number, moduleName) {
    let ls_visible = false;
    if (index != this.tabCount - 1) {
      this.TabModules.forEach(element => {
        if (moduleName === element.Modulepath) {
          if (this.rebate.getItem(element.Modulepath) === true)
            ls_visible = true;
        }
      });
    }
    if (!this.shouldDisableTab(this.tabCount - 1)) {
      ls_visible = true;
    }
    return ls_visible ? 'visible' : 'hidden';
  }
}
