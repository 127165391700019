
<mat-card class="custom-card font-style">
  <!--
  <mat-card-header class="panel-header">
    <div class="panel-title">
      Payee
    </div>
    <div class="panel-icon">
      <mat-icon>more_vert</mat-icon>
    </div>
  </mat-card-header>
  -->
  <mat-card-content style="margin-top: 10px;" class="panel-content custom-card ">
      <form [formGroup]="payeeform" (ngSubmit)="onSave()">
        
        <mat-radio-group formControlName="Payeeoptions" (change)="onPayeeChange($event)">
             
          <mat-radio-button style="margin-right: 10px" *ngFor="let payee of Payeeoptions"    [value]="payee.PayeeOpt_id" [checked]="selectedOption === payee.PayeeOpt_id">
            {{ payee.PayeeOptions }}
          </mat-radio-button>
        </mat-radio-group>
       
      <div class="form-row">
      <mat-form-field class="green-border" style="padding-top: 20px;"  class="full-width" floatLabel="auto" appearance="outline" color="primary">
       
       
   
        <input (keydown)="onFormChange()" (keypress)="onFormChange()" type="text" matInput [class.disabled-input]="payeereadonly"  [readonly]="payeereadonly" placeholder="Customer Name" formControlName="customerName"  />
          <mat-label>Name</mat-label>
          <mat-hint align="end">Up to 40 characters</mat-hint>
          <mat-icon matPrefix>person</mat-icon>
      
      </mat-form-field>
  </div>
  <div class="form-row" style="padding-top: 10px;">
      <mat-form-field class="third-width"   floatLabel="auto" appearance="outline" color="primary">
        <input (keydown)="onFormChange()" (keypress)="onFormChange()" type="text" matInput  placeholder="Street Number" formControlName="streetNumber"  />
        <mat-label>Street Number</mat-label>
        <mat-hint align="end">Up to 20 characters</mat-hint>
        <mat-icon matPrefix>streetview</mat-icon>
        
      </mat-form-field>
  
      <mat-form-field class="third-width"  floatLabel="auto" appearance="outline" color="primary">
        <input (keydown)="onFormChange()" (keypress)="onFormChange()" type="text" matInput  placeholder="Street Name" formControlName="streetName"  />
        <mat-label>Street Name</mat-label>
        <mat-hint align="end">Up to 20 characters</mat-hint>
        <mat-icon matPrefix>map</mat-icon>
      </mat-form-field>
  
      <mat-form-field class="third-width" floatLabel="auto" appearance="outline" color="primary">
        <input (keydown)="onFormChange()" (keypress)="onFormChange()" type="text" matInput  placeholder="Apartment" formControlName="apartment"  />
        <mat-label>Apartment</mat-label>
        <mat-hint align="end">Up to 10 characters</mat-hint>
        <mat-icon matPrefix>business</mat-icon>
      </mat-form-field>
    </div>

    <div class="form-row" style="padding-top: 10px;">
      <mat-form-field class="third-width" floatLabel="auto" appearance="outline" color="primary">
        <input (keydown)="onFormChange()" (keypress)="onFormChange()" type="text" matInput [class.disabled-input]="payeereadonly"  [readonly]="payeereadonly" placeholder="City" formControlName="city"  />
        <mat-label>City</mat-label>
        <mat-hint align="end">Up to 20 characters</mat-hint>
        <mat-icon matPrefix>location_city</mat-icon>
      </mat-form-field>
  
      <mat-form-field class="third-width" floatLabel="auto" appearance="outline" color="primary">
        <input (keydown)="onFormChange()" (keypress)="onFormChange()" type="text" matInput [class.disabled-input]="payeereadonly"  [readonly]="payeereadonly" placeholder="State" formControlName="state"  />
        <mat-label>State</mat-label>
        <mat-hint align="end">Up to 20 characters</mat-hint>
        <mat-icon matPrefix >place</mat-icon>
     
      </mat-form-field>
  
      <mat-form-field class="third-width" floatLabel="auto" appearance="outline" color="primary">
        <input (keydown)="onFormChange()" (keypress)="onFormChange()" type="text" matInput [class.disabled-input]="payeereadonly"  [readonly]="payeereadonly" placeholder="Zip" formControlName="zip"  />
        <mat-label>Zip</mat-label>
        <mat-hint align="end">Up to 10 characters</mat-hint>
        <mat-icon matPrefix>local_post_office</mat-icon>
      </mat-form-field>
    </div>
        
        <h5 style="text-align: center;font-size:15px;margin-top: 20px;"><b>Note:</b> {{ls_payee_message}}</h5>
       
        
        <div class="button-container ">
        <button class="mainbutton font-style" #myButtonRef type="submit" [disabled]="!isSubmitEnabled" mat-flat-button >Save
        </button>
      </div>
      
  <!--
      <div>
        {{payeeform.value | json}}
      </div>
      -->
      </form>
  </mat-card-content>
</mat-card>